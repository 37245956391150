@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy/Gilroy-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy/Gilroy-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy/Gilroy-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy/Gilroy-Black.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animate-200 {
  @apply transition-all duration-200;
}

.animate-300 {
  @apply transition-all duration-300;
}

.blue-button {
  @apply 
    w-fit h-fit px-4 py-2 rounded-full bg-dd-blue text-white font-semibold cursor-pointer select-none animate-200
    hover:bg-dd-blue/80
    active:bg-dd-blue/60
} 

.green-button {
  @apply 
    w-fit h-fit px-4 py-2 rounded-full bg-dd-mint text-dd-blue font-semibold cursor-pointer select-none animate-200
    hover:bg-dd-mint/70
    active:bg-dd-mint/80 
} 

.purple-button {
  @apply 
    w-fit h-fit px-4 py-2 rounded-full bg-dd-green text-white font-semibold cursor-pointer select-none animate-200
    hover:bg-dd-blue/80
    active:bg-dd-blue/60
} 

.background-gradient {
  @apply bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600;
  /* @apply bg-gradient-to-r from-gradient-1/10 via-gradient-4/70 via-gradient-2/40 via-gradient-3/20 to-gradient-4/70; */
}

.background-gradient-dark {
  @apply bg-gradient-to-r from-dd-blue from-[00%] to-dd-green;
}

@keyframes blink {
  0% {opacity: 1;}
  49% {opacity: 1;}
  50% {opacity: 0;} 
  100% {opacity: 0;}
}

.cursor {
  animation: .7s blink infinite;
}

.inputfield {
  @apply bg-[#F7F7F7] w-full rounded-full px-4 py-1 text-sm shadow-md placeholder:text-dd-blue focus:ring-0 focus:outline-dd-blue;
}

.box-rotate {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.black-color{
  @apply text-[#000033]
}

.text-h1 {
  @apply text-[54px] font-black leading-[68px] text-dd-blue
}

.text-h2 {
  @apply text-[36px] font-black leading-[54px] 
}

.text-h3 {
  @apply text-[32px] font-bold leading-[48px] 
}

.text-h4-black {
  @apply text-[24px] font-black leading-[38px] text-dd-blue
}

.text-h4-medium {
  @apply text-[24px] font-medium leading-[38px] 
}

.text-h5 {
  @apply text-[20px] font-medium leading-[27px] 
}

.text-body {
  @apply text-[16px] font-regular leading-[28px] 
}

.text-link {
  @apply text-[16px] font-bold leading-[28px] 
}